import React from "react";
import "./App.scss";
import "./i18n.js";
import { Amplify, Auth, Hub } from "aws-amplify";
import AuthContext from "./components/AuthContext.js";

import Dashboard from "./components/Dashboard/Dashboard";
import AfterSigninPage from "./pages/AfterSigninPage/AfterSigninPage";
import CustomButton from "./components/CustomButton/CustomButton";
import LandingPageHeader from "./components/LandingPageHeader/LandingPageHeader";
import { getStripeLink } from "./utils/getStripeLink";
import { useLocation, useNavigate } from "react-router-dom";
import { gameList } from "./gameList";
import SuggestGame from "./components/SuggestGame/SuggestGame";
import Footer from "./components/Footer/Footer";
import TestimonialCarousel from "./components/TestimonialCarousel/TestimonialCarousel";
import HowItWorks from "./components/HowItWorks/HowItWorks";
import Testimonials from "./components/Testimonials/Testimonials";
import GameList from "./components/GameList/GameList";
import { Link } from "react-router-dom";
import SignedOutHeader from "./components/SignedOutHeader/SignedOutHeader.js";
import ReferredBanner from "./components/ReferredBanner/ReferredBanner.js";
import { withTranslation } from "react-i18next";
import ReferralBanner from "./components/ReferralBanner/ReferralBanner.js";

class App extends React.Component {
  componentDidMount() {
    const params = new URLSearchParams(window.location.search);
    const referrerUsername = params.get("referrerUsername");
    if (referrerUsername) {
      sessionStorage.setItem("referrerUsername", referrerUsername);
    }
  }
  static contextType = AuthContext;

  render() {
    const { t } = this.props;
    const params = new URLSearchParams(window.location.search);
    return (
      <main>
        {this.context.user ? (
          <AfterSigninPage
            hideTrialDecisionPage={true}
            setTrialDecisionMade={this.setTrialDecisionMade}
            signOut={this.context.signOut}
            user={this.context.user}
            userEmail={this.context.userEmail}
          />
        ) : (
          <div className="pt-0 max-w-[1245px] px-[24px] my-0 mx-auto min-h-screen">
            <SignedOutHeader context={this.context} />
            {params.get("referrerUsername") ||
            sessionStorage.getItem("referrerUsername") ? (
              <ReferredBanner
                show={
                  params.get("referrerUsername") ||
                  sessionStorage.getItem("referrerUsername")
                }
                signedOut={true}
              />
            ) : (
              <ReferralBanner />
            )}

            <section className="flex flex-col md:flex-row justify-between min-h-[700px] mt-[12px]">
              <div className="flex flex-col justify-center w-fit md:w-2/3 gap-6">
                <h1 className="mb-4 text-4xl font-extrabold leading-none tracking-tight md:text-5xl lg:text-6xltext-white">
                  {t("heading")}
                </h1>

                <h4 className="mb-6 text-lg font-normal lg:text-xl text-base-content">
                  <span dangerouslySetInnerHTML={{ __html: t("subheading") }} />
                </h4>
                <a className="btn btn-primary max-w-md btn-lg" href="/signup">
                  <button>{t("signup")}</button>
                </a>

                {/*<img alt="" className="controller-image" src="./controller.svg" />*/}
              </div>

              <div className="flex justify-evenly p-[16px] w-full md:w-1/3">
                <div className="flex flex-col justify-center">
                  <img
                    alt="GTA V Cover Art"
                    className="w-[150px] h-auto p-[10px]"
                    src="./gta_v.webp"
                  />
                  <img
                    alt="Cricket 24 Cover Art"
                    className="w-[150px] h-auto p-[10px]"
                    src="./cricket24.webp"
                  />
                  <img
                    alt="FC 24 Cover Art"
                    className="w-[150px] h-auto p-[10px]"
                    src="./fc24.webp"
                  />
                </div>
                <div className="flex flex-col justify-center">
                  <img
                    alt="Marvel's Spider-Man Remastered Cover Art"
                    className="w-[150px] h-auto p-[10px]"
                    src="./spiderman.webp"
                  />
                  <img
                    alt="WWE 2K23 Cover Art"
                    className="w-[150px] h-auto p-[10px]"
                    src="./wwe_2k23.webp"
                  />
                  <img
                    alt="STAR WARS Jedi: Survivor Cover Art"
                    className="w-[150px] h-auto p-[10px]"
                    src="./jedi_survivor.jpg"
                  />
                </div>
              </div>
            </section>
            <section className="pt-16" ref={this.productsRef}>
              <h2 className="text-4xl font-extrabold no-underline pb-10">
                {t("products")}
              </h2>
              <div className="flex flex-col w-fit md:flex-row">
                <div className="grid flex-grow h-fit card bg-base-300 rounded-box place-items-left gap-6 p-6">
                  <h3 className="text-3xl font-bold text-base-content">
                    {t("virtual_gaming_pc")}
                  </h3>
                  <li className="flex items-center">
                    <svg
                      className="w-3.5 h-3.5 me-2 text-success dark:text-success flex-shrink-0"
                      aria-hidden="true"
                      xmlns="http://www.w3.org/2000/svg"
                      fill="currentColor"
                      viewBox="0 0 20 20"
                    >
                      <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5Zm3.707 8.207-4 4a1 1 0 0 1-1.414 0l-2-2a1 1 0 0 1 1.414-1.414L9 10.586l3.293-3.293a1 1 0 0 1 1.414 1.414Z" />
                    </svg>
                    {t("virtual_pc_description")}
                  </li>
                  <li className="flex items-center">
                    <svg
                      className="w-3.5 h-3.5 me-2 text-success dark:text-success flex-shrink-0"
                      aria-hidden="true"
                      xmlns="http://www.w3.org/2000/svg"
                      fill="currentColor"
                      viewBox="0 0 20 20"
                    >
                      <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5Zm3.707 8.207-4 4a1 1 0 0 1-1.414 0l-2-2a1 1 0 0 1 1.414-1.414L9 10.586l3.293-3.293a1 1 0 0 1 1.414 1.414Z" />
                    </svg>
                    {t("virtual_gaming_pc_install")}
                  </li>
                  <li className="flex items-center">
                    <svg
                      className="w-3.5 h-3.5 me-2 text-success dark:text-success flex-shrink-0"
                      aria-hidden="true"
                      xmlns="http://www.w3.org/2000/svg"
                      fill="currentColor"
                      viewBox="0 0 20 20"
                    >
                      <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5Zm3.707 8.207-4 4a1 1 0 0 1-1.414 0l-2-2a1 1 0 0 1 1.414-1.414L9 10.586l3.293-3.293a1 1 0 0 1 1.414 1.414Z" />
                    </svg>
                    <div>
                      <span
                        dangerouslySetInnerHTML={{
                          __html: t("virtual_gaming_pc_rent"),
                        }}
                      />
                    </div>
                  </li>
                  <li className="flex items-center">
                    <svg
                      className="w-3.5 h-3.5 me-2 text-success dark:text-success flex-shrink-0"
                      aria-hidden="true"
                      xmlns="http://www.w3.org/2000/svg"
                      fill="currentColor"
                      viewBox="0 0 20 20"
                    >
                      <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5Zm3.707 8.207-4 4a1 1 0 0 1-1.414 0l-2-2a1 1 0 0 1 1.414-1.414L9 10.586l3.293-3.293a1 1 0 0 1 1.414 1.414Z" />
                    </svg>
                    <div>
                      <span
                        dangerouslySetInnerHTML={{
                          __html: t("play_pre_installed_games"),
                        }}
                      />
                    </div>
                  </li>
                  <li className="flex items-center">
                    <svg
                      className="w-3.5 h-3.5 me-2 text-success dark:text-success flex-shrink-0"
                      aria-hidden="true"
                      xmlns="http://www.w3.org/2000/svg"
                      fill="currentColor"
                      viewBox="0 0 20 20"
                    >
                      <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5Zm3.707 8.207-4 4a1 1 0 0 1-1.414 0l-2-2a1 1 0 0 1 1.414-1.414L9 10.586l3.293-3.293a1 1 0 0 1 1.414 1.414Z" />
                    </svg>
                    {t("virtual_gaming_pc_servers")}
                  </li>
                  <Link className="btn btn-secondary btn-lg" to="/pricing">
                    {t("see_pricing")}
                  </Link>
                </div>
                <div className="divider md:divider-horizontal">OR</div>
                <div className="grid flex-grow h-fit card bg-base-300 rounded-box place-items-left gap-6 p-6">
                  <h3 className="text-3xl font-bold text-base-content">
                    {t("pre_installed_games")}
                  </h3>
                  <li className="flex items-center">
                    <svg
                      className="w-3.5 h-3.5 me-2 text-success dark:text-success flex-shrink-0"
                      aria-hidden="true"
                      xmlns="http://www.w3.org/2000/svg"
                      fill="currentColor"
                      viewBox="0 0 20 20"
                    >
                      <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5Zm3.707 8.207-4 4a1 1 0 0 1-1.414 0l-2-2a1 1 0 0 1 1.414-1.414L9 10.586l3.293-3.293a1 1 0 0 1 1.414 1.414Z" />
                    </svg>
                    <div>
                      <span
                        dangerouslySetInnerHTML={{
                          __html: t("play_pre_installed_games"),
                        }}
                      />
                    </div>
                  </li>
                  <li className="flex items-center">
                    <svg
                      className="w-3.5 h-3.5 me-2 text-success dark:text-success flex-shrink-0"
                      aria-hidden="true"
                      xmlns="http://www.w3.org/2000/svg"
                      fill="currentColor"
                      viewBox="0 0 20 20"
                    >
                      <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5Zm3.707 8.207-4 4a1 1 0 0 1-1.414 0l-2-2a1 1 0 0 1 1.414-1.414L9 10.586l3.293-3.293a1 1 0 0 1 1.414 1.414Z" />
                    </svg>
                    {t("pre_installed_games_buy")}
                  </li>
                  <li className="flex items-center">
                    <svg
                      className="w-3.5 h-3.5 me-2 text-neutral dark:text-base-neutral flex-shrink-0"
                      aria-hidden="true"
                      xmlns="http://www.w3.org/2000/svg"
                      fill="currentColor"
                      viewBox="0 0 20 20"
                    >
                      <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5Zm3.707 8.207-4 4a1 1 0 0 1-1.414 0l-2-2a1 1 0 0 1 1.414-1.414L9 10.586l3.293-3.293a1 1 0 0 1 1.414 1.414Z" />
                    </svg>
                    {t("pre_installed_games_data")}
                  </li>
                  <li className="flex items-center">
                    <svg
                      className="w-3.5 h-3.5 me-2 text-neutral dark:text-base-neutral flex-shrink-0"
                      aria-hidden="true"
                      xmlns="http://www.w3.org/2000/svg"
                      fill="currentColor"
                      viewBox="0 0 20 20"
                    >
                      <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5Zm3.707 8.207-4 4a1 1 0 0 1-1.414 0l-2-2a1 1 0 0 1 1.414-1.414L9 10.586l3.293-3.293a1 1 0 0 1 1.414 1.414Z" />
                    </svg>
                    {t("pre_installed_games_cloud")}
                  </li>
                  <li className="flex items-center">
                    <svg
                      className="w-3.5 h-3.5 me-2 text-neutral dark:text-base-neutral flex-shrink-0"
                      aria-hidden="true"
                      xmlns="http://www.w3.org/2000/svg"
                      fill="currentColor"
                      viewBox="0 0 20 20"
                    >
                      <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5Zm3.707 8.207-4 4a1 1 0 0 1-1.414 0l-2-2a1 1 0 0 1 1.414-1.414L9 10.586l3.293-3.293a1 1 0 0 1 1.414 1.414Z" />
                    </svg>
                    {t("pre_installed_games_server")}
                  </li>
                  <Link className="btn btn-secondary btn-lg" to="/pricing">
                    {t("see_pricing")}
                  </Link>
                </div>
              </div>
            </section>
            <HowItWorks howItWorksRef={this.howItWorksRef} />
            <Testimonials testimonialRef={this.testimonialRef} />
            <Footer />
          </div>
        )}
      </main>
    );
  }

  setTrialDecisionMade = () => {
    localStorage.setItem("hideTrialDecisionPage", "true");
    this.setState({
      hideTrialDecisionPage: true,
    });
  };
}

export default withTranslation()(App);
