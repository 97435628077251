import { useState, useEffect } from "react";
import React from "react";
import clsx from "classnames";
import AuthContext from "../AuthContext";
import SignedOutHeader from "../SignedOutHeader/SignedOutHeader";
import Footer from "../Footer/Footer";
import { usePostHog } from "posthog-js/react";
import { Link, useNavigate } from "react-router-dom";
import ReferralBanner from "../ReferralBanner/ReferralBanner";
import { useTranslation } from "react-i18next";

export default function PricingPage2() {
  const navigate = useNavigate();
  const posthog = usePostHog();
  const { t } = useTranslation();
  // pass useState() the initial value

  // machineType can be "ondemand", "100gb", "200gb", "1tb"
  const [machineType, setMachineType] = useState("100gb");
  const [vmPaymentPeriod, setVmPaymentPeriod] = useState("week");
  const [hoursPaymentPeriod, setHoursPaymentPeriod] = useState("week");
  const [vmPaymentType, setVmPaymentType] = useState("subscription");
  const [vmPaymentGateway, setVmPaymentGateway] = useState("stripe");
  const [hoursPaymentType, setHoursPaymentType] = useState("subscription");
  const [hoursPaymentGateway, setHoursPaymentGateway] = useState("stripe");
  const [checkoutLoading, setCheckoutLoading] = useState(false);
  const [checkoutEmail, setCheckoutEmail] = useState(null);
  const machineTypeLabel = {
    ondemand: "On-demand",
    "100gb": "100 GB",
    "200gb": "200 GB",
  };

  const loadScript = (src) => {
    return new Promise((resolve) => {
      const script = document.createElement("script");
      script.src = src;
      script.onload = () => {
        resolve(true);
      };
      script.onerror = () => {
        resolve(false);
      };
      document.body.appendChild(script);
    });
  };

  const RAZORPAY_SCRIPT_URL = "https://checkout.razorpay.com/v1/checkout.js";
  const RAZORPAY_KEY = "rzp_live_u8YAfYrwkuCTLq";

  const hoursSubscriptionPriceId =
    process.env.NODE_ENV === "development"
      ? {
          month: "price_1OlEwWSAcn2IgTItg9eqME0Z",
          week: "price_1OlEw6SAcn2IgTIttxw05mbK",
        }
      : {
          month: "price_1OmJqrSAcn2IgTItNc9VNvGA",
          week: "price_1OmJqrSAcn2IgTItsEFemcYj",
        };

  // The code here will only run in development mode
  const machineTypePaymentLink =
    process.env.NODE_ENV === "development"
      ? {
          "100gb": {
            payment: {
              week: "price_1OlEWbSAcn2IgTItPKlCzqP3",
              month: "price_1OiLUaSAcn2IgTItxEZnAaKE",
            },
            subscription: {
              week: "price_1OlEVgSAcn2IgTIt3qo8KhCs",
              month: "price_1OiLVTSAcn2IgTItL1T8naGJ",
            },
          },
          "200gb": {
            payment: {
              week: "price_1OhyAaSAcn2IgTItK3KDbHOq",
              month: "price_1OiLV2SAcn2IgTItqWOf4BDA",
            },
            subscription: {
              week: "price_1OhyAxSAcn2IgTItLZwGVJ09",
              month: "price_1OiLWPSAcn2IgTItjYKsgpfP",
            },
          },
        }
      : {
          "100gb": {
            payment: {
              week: "price_1OmJq3SAcn2IgTItyuznQrVI",
              month: "price_1OmJqISAcn2IgTItSSzuzoc4",
            },
            subscription: {
              week: "price_1OmJq3SAcn2IgTItNrLumyWE",
              month: "price_1OmJqISAcn2IgTIt4hQPs20M",
            },
          },
          "200gb": {
            payment: {
              week: "price_1OmJqZSAcn2IgTItvVIMRb1J",
              month: "price_1OmJqCSAcn2IgTItYkyBff7X",
            },
            subscription: {
              week: "price_1OmJqZSAcn2IgTItbXlXJAyo",
              month: "price_1OmJqCSAcn2IgTItOt2z15vG",
            },
          },
        };

  const machineTypePaymentPrice = {
    "100gb": {
      subscription: {
        week: "150",
        month: "550",
      },
      payment: {
        week: "160",
        month: "580",
      },
    },
    "200gb": {
      subscription: {
        week: "300",
        month: "1100",
      },
      payment: {
        week: "320",
        month: "1160",
      },
    },
  };
  const [hours, setHours] = useState(1);
  const { user, signOut, signIn, userEmail } = React.useContext(AuthContext);

  // useEffect - look up and read / watch videos about how this works.

  useEffect(() => {
    if (machineType !== "ondemand") {
      setHours(20);
    }
  }, [machineType]);

  useEffect(() => {
    loadScript(RAZORPAY_SCRIPT_URL).then((loaded) => {
      if (!loaded) {
        console.log(
          "Failed to load Razorpay SDK. Please check your internet connection and try again."
        );
      }
    });
  }, []);

  async function handleBuyClick(
    priceId,
    payment_mode,
    quantity,
    payment_gateway,
    isHourlyPlan
  ) {
    if (!user) {
      signIn();
    }
    posthog?.capture("purchase_plan", {
      priceId,
      payment_mode,
      quantity,
      vmPaymentPeriod,
      vmPaymentType,
      hoursPaymentPeriod,
      hoursPaymentType,
      userEmail,
    });
    setCheckoutLoading(true);
    await fetch(
      payment_gateway === "stripe"
        ? process.env.NODE_ENV === "development"
          ? "https://1ku3nifl2e.execute-api.ap-south-1.amazonaws.com/default/getStripeCheckout-dev-hello"
          : "https://v1wapno6ch.execute-api.ap-south-1.amazonaws.com/prod/getStripeCheckout-prod-hello"
        : process.env.NODE_ENV === "development"
        ? "https://9mljq6r4e3.execute-api.ap-south-1.amazonaws.com/default/getRazorpayCheckout-dev-hello"
        : "https://d58cycm35h.execute-api.ap-south-1.amazonaws.com/default/getRazorpayCheckout-prod-hello",
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json;charset=UTF-8",
          Authorization: user.signInUserSession.idToken.jwtToken,
        },
        body: JSON.stringify({
          priceId: priceId,
          isHourlyPlan: isHourlyPlan,
          adjustableQuantity: false,
          payment_mode: payment_mode,
          quantity: quantity,
          email: checkoutEmail,
          vmMachineType: machineType,
          vmPaymentPeriod: vmPaymentPeriod,
          vmPaymentType: vmPaymentType,
          hoursPaymentPeriod: hoursPaymentPeriod,
          hoursPaymentType: hoursPaymentType,
        }),
      }
    )
      .then((response) => response.json())
      .then((data) => {
        if (data.checkout_url) {
          window.location.href = data.checkout_url;
        } else if (data.order_id && payment_gateway === "razorpay") {
          const options = {
            key: data.key_id,
            amount: data.amount, // Amount should be in currency subunits
            currency: "INR",
            name: "GameAway",
            order_id: data.order_id,
            handler: function (response) {
              navigate("/");
            },
            prefill: {
              email: checkoutEmail,
            },
            theme: {
              color: "#231955",
            },
          };
          const paymentObject = new window.Razorpay(options);
          paymentObject.open();
        } else if (data.subscription_id && payment_gateway === "razorpay") {
          const options = {
            key: data.key_id, // Enter the Key ID generated from the Razorpay Dashboard
            subscription_id: data.subscription_id,
            name: "GameAway",
            handler: function (response) {
              navigate("/");
            },
            prefill: {
              email: checkoutEmail,
            },
            theme: {
              color: "#231955",
            },
          };
          const paymentObject = new window.Razorpay(options);
          paymentObject.open();
        }
        setCheckoutLoading(false);
      })
      .catch((error) => {
        console.error("Error:", error);
        setCheckoutLoading(false);
      });
  }

  async function handleHoursBuy(quantity, payment_gateway, isHourlyPlan) {
    if (!user) {
      signIn();
    }
    posthog?.capture("purchase_plan", {
      priceId: null,
      payment_mode: "payment",
      vmPaymentPeriod: null,
      vmPaymentType: null,
      hoursPaymentPeriod: null,
      hoursPaymentType: "payment",
      quantity,
      userEmail,
    });
    setCheckoutLoading(true);
    await fetch(
      payment_gateway === "stripe"
        ? process.env.NODE_ENV === "development"
          ? "https://1ku3nifl2e.execute-api.ap-south-1.amazonaws.com/default/getStripeCheckout-dev-hello"
          : "https://v1wapno6ch.execute-api.ap-south-1.amazonaws.com/prod/getStripeCheckout-prod-hello"
        : process.env.NODE_ENV === "development"
        ? "https://9mljq6r4e3.execute-api.ap-south-1.amazonaws.com/default/getRazorpayCheckout-dev-hello"
        : "https://d58cycm35h.execute-api.ap-south-1.amazonaws.com/default/getRazorpayCheckout-prod-hello",
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json;charset=UTF-8",
          Authorization: user.signInUserSession.idToken.jwtToken,
        },
        body: JSON.stringify({
          priceId: null,
          isHourlyPlan: isHourlyPlan,
          adjustableQuantity: false,
          payment_mode: "payment",
          quantity: quantity,
          email: checkoutEmail,
          vmMachineType: machineType,
          vmPaymentPeriod: vmPaymentPeriod,
          vmPaymentType: vmPaymentType,
          hoursPaymentPeriod: hoursPaymentPeriod,
          hoursPaymentType: hoursPaymentType,
        }),
      }
    )
      .then((response) => response.json())
      .then((data) => {
        if (data.checkout_url) {
          window.location.href = data.checkout_url;
        } else if (data.order_id && payment_gateway === "razorpay") {
          const options = {
            key: data.key_id,
            amount: data.amount, // Amount should be in currency subunits
            currency: "INR",
            name: "GameAway",
            order_id: data.order_id,
            handler: function (response) {
              navigate("/");
            },
            prefill: {
              email: checkoutEmail,
            },
            theme: {
              color: "#231955",
            },
          };
          const paymentObject = new window.Razorpay(options);
          paymentObject.open();
        } else if (data.subscription_id && payment_gateway === "razorpay") {
          const options = {
            key: data.key_id, // Enter the Key ID generated from the Razorpay Dashboard
            subscription_id: data.subscription_id,
            name: "GameAway",
            handler: function (response) {
              navigate("/");
            },
            prefill: {
              email: checkoutEmail,
            },
            theme: {
              color: "#231955",
            },
          };
          const paymentObject = new window.Razorpay(options);
          paymentObject.open();
        }
        setCheckoutLoading(false);
      })
      .catch((error) => {
        console.error("Error:", error);
        setCheckoutLoading(false);
      });
  }

  function getHoursPrice(hours) {
    let discountedPrice = 0;
    if (hoursPaymentType === "subscription") {
      if (hours < 10) {
        discountedPrice = hours * 50;
      } else if (hours < 20) {
        discountedPrice = hours * 45;
      } else {
        discountedPrice = hours * 40;
      }
    } else {
      if (hours < 10) {
        discountedPrice = hours * 52.5;
      } else if (hours < 20) {
        discountedPrice = hours * 47.5;
      } else {
        discountedPrice = hours * 42.5;
      }
    }

    return discountedPrice;
  }

  return (
    <div className="pt-0 max-w-[1245px] px-[24px] my-0 mx-auto min-h-screen grid">
      <SignedOutHeader />
      {user ? <ReferralBanner /> : null}
      <input
        className={clsx("w-36 mx-auto", {
          hidden: user.username !== "utkarshdalal",
        })}
        placeholder="customer email"
        onChange={(ev) => setCheckoutEmail(ev.target.value)}
      />
      <div className="relative flex flex-col lg:flex-row mx-auto justify-center items-center gap-2">
        <div className="card w-fit lg:w-1/3 mx-auto bg-base-300 shadow-xl h-fit">
          <div className="card-body gap-6">
            <h2 className="card-title no-underline pb-6">
              {t("virtual_gaming_pc")}
            </h2>
            <div className="text-sm">{t("virtual_gaming_pc_pricing_1")}</div>
            <div className="text-sm">{t("virtual_gaming_pc_pricing_2")}</div>
            <div className="text-sm text-warning">
              {t("virtual_gaming_pc_pricing_3")}
            </div>
            <div className="text-sm font-bold text-warning underline">
              <Link to="/contact-us?source=pricing">
                {t("virtual_gaming_pc_pricing_4")}
              </Link>
            </div>
            <div className="text-sm text-secondary font-extrabold">
              {t("virtual_gaming_pc_pricing_5")}
            </div>
            <ul
              className={clsx(
                "menu menu-horizontal bg-base-200 mx-auto gap-2",
                {
                  "pointer-events-none opacity-20": machineType === "ondemand",
                }
              )}
            >
              <li
                onClick={() => {
                  setMachineType("100gb");
                }}
              >
                <a
                  className={clsx({
                    "bg-secondary font-bold text-secondary-content":
                      machineType === "100gb",
                  })}
                >
                  100 GB
                </a>
              </li>
              <li
                onClick={() => {
                  setMachineType("200gb");
                }}
              >
                <a
                  className={clsx({
                    "bg-secondary font-bold text-secondary-content":
                      machineType === "200gb",
                  })}
                >
                  200 GB
                </a>
              </li>
            </ul>
            <ul
              className={clsx(
                "menu menu-horizontal bg-base-200 mx-auto gap-2",
                {
                  "pointer-events-none opacity-20": machineType === "ondemand",
                }
              )}
            >
              <li
                onClick={() => {
                  setVmPaymentPeriod("week");
                }}
              >
                <a
                  className={clsx({
                    "bg-secondary font-bold text-secondary-content":
                      vmPaymentPeriod === "week",
                  })}
                >
                  {t("one_week")}
                </a>
              </li>
              <li
                onClick={() => {
                  setVmPaymentPeriod("month");
                }}
              >
                <a
                  className={clsx({
                    "bg-secondary font-bold text-secondary-content":
                      vmPaymentPeriod === "month",
                  })}
                >
                  {t("one_month")}
                </a>
              </li>
            </ul>
            <div className="form-control">
              <label className="label cursor-pointer">
                <span className="label-text">{t("subscribe_and_save")}</span>
                <input
                  onChange={() =>
                    vmPaymentType === "subscription"
                      ? setVmPaymentType("payment")
                      : setVmPaymentType("subscription")
                  }
                  type="checkbox"
                  className={clsx("toggle", {
                    "toggle-primary": vmPaymentType === "subscription",
                  })}
                  checked={vmPaymentType === "subscription"}
                />
              </label>
            </div>
            <div className="text-3xl text-center">
              {`₹${machineTypePaymentPrice[machineType][vmPaymentType][vmPaymentPeriod]}`}{" "}
              {vmPaymentType === "subscription"
                ? `${t("per")} ${t(vmPaymentPeriod)}`
                : ""}
              *
            </div>
            <div className="text-xs text-center">* +18% GST</div>
            <div className="gap-6 flex flex-row justify-evenly">
              <div className="text-center justify-center margin-auto flex gap-2">
                <input
                  type="radio"
                  name="radio-1"
                  className="radio radio-primary"
                  defaultChecked
                  onChange={() => {
                    setVmPaymentGateway("stripe");
                  }}
                />
                {t("card")}
              </div>

              <div className="text-center justify-center margin-auto flex gap-2">
                <input
                  type="radio"
                  name="radio-1"
                  className="radio radio-primary"
                  onChange={() => {
                    setVmPaymentGateway("razorpay");
                  }}
                />
                {t("upi")}
              </div>
            </div>
            <div className="card-actions justify-end">
              <button
                className={clsx("btn btn-primary w-full", {
                  "btn-disabled": checkoutLoading,
                })}
                onClick={() =>
                  handleBuyClick(
                    machineTypePaymentLink[machineType][vmPaymentType][
                      vmPaymentPeriod
                    ],
                    vmPaymentType,
                    1,
                    vmPaymentGateway,
                    false
                  )
                }
              >
                {vmPaymentType === "subscription"
                  ? t("subscribe_vm", {
                      vmPaymentPeriod: t(vmPaymentPeriod),
                    })
                  : t("buy_vm")}
              </button>
            </div>
          </div>
        </div>
        <div className="divider lg:divider-horizontal"></div>
        <div className="card w-fit lg:w-1/3 mx-auto bg-base-300 shadow-xl h-fit">
          <div className="card-body gap-6">
            <h2 className="card-title no-underline">{t("add_playtime")}</h2>
            <div className="text-sm">{t("playtime_pricing_1")}</div>
            <div className="text-sm text-warning">
              {t("playtime_pricing_2")}
            </div>
            <input
              type="range"
              min={1}
              max="50"
              value={hours}
              className="range"
              step="1"
              onChange={(ev) => setHours(parseInt(ev.target.value))}
            />
            <div className="w-full flex justify-between text-xs px-2">
              <span>1</span>
              <span>10</span>
              <span>20</span>
              <span>30</span>
              <span>40</span>
              <span>50</span>
            </div>
            <div className="form-control">
              <label className="label cursor-pointer">
                <span className="label-text">{t("subscribe_and_save")}</span>
                <input
                  onChange={() =>
                    hoursPaymentType === "subscription"
                      ? setHoursPaymentType("payment")
                      : setHoursPaymentType("subscription")
                  }
                  type="checkbox"
                  className={clsx("toggle", {
                    "toggle-primary": hoursPaymentType === "subscription",
                  })}
                  checked={hoursPaymentType === "subscription"}
                />
              </label>
            </div>
            <ul
              className={clsx(
                "menu menu-horizontal bg-base-200 mx-auto gap-2",
                {
                  "pointer-events-none opacity-20":
                    hoursPaymentType !== "subscription",
                }
              )}
            >
              <li
                onClick={() => {
                  setHoursPaymentPeriod("week");
                }}
              >
                <a
                  className={clsx({
                    "bg-secondary font-bold text-secondary-content":
                      hoursPaymentPeriod === "week",
                  })}
                >
                  {t("weekly")}
                </a>
              </li>
              <li
                onClick={() => {
                  setHoursPaymentPeriod("month");
                }}
              >
                <a
                  className={clsx({
                    "bg-secondary font-bold text-secondary-content":
                      hoursPaymentPeriod === "month",
                  })}
                >
                  {t("monthly")}
                </a>
              </li>
            </ul>
            <div className="flex flex-row gap-4 text-center mx-auto">
              <div
                className={clsx(
                  "text-3xl text-center line-through text-error",
                  {
                    hidden: hours < 10 && hoursPaymentType === "payment",
                  }
                )}
              >{`₹${hours * 52.5}`}</div>
              <div className="text-3xl text-center">
                {`₹${getHoursPrice(hours)}`}{" "}
                {hoursPaymentType === "subscription"
                  ? `${t("per")} ${t(hoursPaymentPeriod)}`
                  : ""}
                *
              </div>
            </div>
            <div className="text-xs text-center">* +18% GST</div>
            <div className="gap-6 flex flex-row justify-evenly">
              <div className="text-center justify-center margin-auto flex gap-2">
                <input
                  type="radio"
                  name="radio-2"
                  className="radio radio-primary"
                  defaultChecked
                  onChange={() => {
                    setHoursPaymentGateway("stripe");
                  }}
                />
                {t("card")}
              </div>

              <div className="text-center justify-center margin-auto flex gap-2">
                <input
                  type="radio"
                  name="radio-2"
                  className="radio radio-primary"
                  onChange={() => {
                    setHoursPaymentGateway("razorpay");
                  }}
                />
                {t("upi")}
              </div>
            </div>
            <div className="card-actions justify-end">
              <button
                className={clsx("btn btn-primary w-full", {
                  "btn-disabled": checkoutLoading,
                })}
                onClick={() =>
                  hoursPaymentType === "subscription"
                    ? handleBuyClick(
                        hoursSubscriptionPriceId[hoursPaymentPeriod],
                        hoursPaymentType,
                        hours,
                        hoursPaymentGateway,
                        true
                      )
                    : handleHoursBuy(hours, hoursPaymentGateway, true)
                }
              >
                {hoursPaymentType === "subscription"
                  ? t("subscribe_hours", {
                      hours: hours,
                      period: t(hoursPaymentPeriod),
                    })
                  : t("buy_hours", { hours: hours })}
              </button>
            </div>
            <div className="text-xs text-center underline"></div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}
