import React, { useState, useEffect, useRef } from "react";
import "./Dashboard.scss";
import CustomButton from "../CustomButton/CustomButton";
import {
  MdCheck,
  MdCopyAll,
  MdOutlinePlayCircleFilled,
  MdStopCircle,
} from "react-icons/md";
import classNames from "classnames";
import GameSelection from "../GameSelection/GameSelection";
import { Link } from "react-router-dom";
import { isDateInPast } from "../../utils/isDateInPast";
import InstructionsContainer from "../InstructionsContainer/InstructionsContainer";
import CustomerSupport from "../CustomerSupport/CustomerSupport";
import SuggestGame from "../SuggestGame/SuggestGame";
import {
  ExclamationCircleIcon,
  CheckCircleIcon,
  ComputerDesktopIcon,
} from "@heroicons/react/24/solid";
import { usePostHog } from "posthog-js/react";
import clsx from "classnames";
import ReferralBanner from "../ReferralBanner/ReferralBanner";
import { useTranslation } from "react-i18next";

const gamingRigStatusLink =
  "https://jly4ch5l57.execute-api.ap-south-1.amazonaws.com/default/gamingRigStatus";

const gamingRigStatusOptions = {
  client_loading: "client_loading",
  not_running: "not_running",
  running: "running",
  booting: "booting",
};

const isNewGameFlagSet = () => {
  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);
  const allowNewGame = urlParams.get("allowNewGame");
  return !!(allowNewGame && allowNewGame === "true");
};

const defaultSelectedGame = "Grand Theft Auto V Steam";

const Dashboard = ({ user }) => {
  const posthog = usePostHog();
  const allowNewGameFlag = useRef(isNewGameFlagSet()).current;
  const [state, setState] = useState({
    gamingRigStatus: gamingRigStatusOptions.client_loading,
    gamingRigIp: false,
    hoursRemaining: "Unknown",
    pin: "",
    pinUrl: "",
    pinSubmitted: false,
    areInstructionsExpanded: true,
    showCopiedTooltip: false,
    showPinLoader: false,
    showPinTick: false,
    gameName: defaultSelectedGame,
    newGameName: "",
    newGameStore: "steam",
    gameSize: 0,
    loadingRigIsStartingStopping: false,
    hasShownRigStartNotification: false,
    allowNewGame: !!allowNewGameFlag,
    suggestedGame: "",
    isGameSuggested: false,
    showGameSuggestion: false,
    paymentPlanExpiryDate: "",
    startGameRequestError: false,
    allowTwoHoursForFifty: false,
    isHourlyPlan: true,
    accessAllGames: false,
    progress: 0,
    isYourOwnGamePlan: false,
    vm: false,
    vmDate: null,
    closestRegion: null,
    hasHours: false,
    startGamePleaseWait: false,
    region: "ap-south-1",
  });
  const [checkoutEmail, setCheckoutEmail] = useState(null);

  const intervalRef = useRef(null);
  const { t } = useTranslation();

  useEffect(() => {
    const requestNotificationPermission = async () => {
      if (Notification && Notification.requestPermission) {
        try {
          await Notification.requestPermission();
        } catch (err) {
          console.log("notification error", err);
        }
      }
    };
    const determineRegion = async () => {
      try {
        // Fetch the IP address
        const ipResponse = await fetch("https://api.ipify.org?format=json");
        const ipData = await ipResponse.json();
        const ip = ipData.ip;

        // Fetch location data using the IP
        const locationResponse = await fetch(`https://ipinfo.io/${ip}/json`);
        const locationData = await locationResponse.json();

        const locations = {
          Mumbai: { lat: 19.076, lon: 72.8777 },
          Bahrain: { lat: 26.0667, lon: 50.5577 },
          "Hong Kong": { lat: 22.3193, lon: 114.1694 },
          Milan: { lat: 45.4642, lon: 9.19 },
        };

        const userLat = parseFloat(locationData.loc.split(",")[0]);
        const userLon = parseFloat(locationData.loc.split(",")[1]);

        let minDistance = Number.MAX_VALUE;
        let closestCity = "";
        Object.keys(locations).forEach((city) => {
          const distance = Math.sqrt(
            Math.pow(locations[city].lat - userLat, 2) +
              Math.pow(locations[city].lon - userLon, 2)
          );
          if (distance < minDistance) {
            minDistance = distance;
            closestCity = city;
          }
        });

        let closestRegion = "ap-south-1";
        if (closestCity === "Mumbai") {
          closestRegion = "ap-south-1";
        } else if (closestCity === "Bahrain") {
          closestRegion = "me-south-1";
        } else if (closestCity === "Hong Kong") {
          closestRegion = "ap-east-1";
        } else if (closestCity === "Milan") {
          closestRegion = "eu-south-1";
        }
        console.log("Closest region is: ", closestRegion);

        setState((prevState) => ({ ...prevState, closestRegion }));
      } catch (error) {
        console.error("Error fetching IP or location:", error);
      }
    };
    requestNotificationPermission();
    checkInstanceState();
    determineRegion();

    return () => {
      if (
        state.gamingRigStatus !== gamingRigStatusOptions.booting &&
        state.gamingRigStatus !== gamingRigStatusOptions.client_loading
      ) {
        clearInterval(intervalRef.current);
        localStorage.removeItem("loaderStartTime");
      }
    };
  }, []);

  useEffect(() => {
    if (state.gamingRigStatus === gamingRigStatusOptions.booting) {
      if (!localStorage.getItem("loaderStartTime")) {
        localStorage.setItem("loaderStartTime", Date.now());
      }
      intervalRef.current = setInterval(() => {
        const elapsedTime =
          Date.now() - Number(localStorage.getItem("loaderStartTime"));
        const progress = Math.min((elapsedTime / (4 * 60 * 1000)) * 100, 100);
        setState((prevState) => ({ ...prevState, progress }));
        if (progress >= 100) {
          clearInterval(intervalRef.current);
          localStorage.removeItem("loaderStartTime");
        }
      }, 1000);
    } else if (
      state.gamingRigStatus !== gamingRigStatusOptions.client_loading
    ) {
      clearInterval(intervalRef.current);
      setState((prevState) => ({ ...prevState, progress: 0 }));
      localStorage.removeItem("loaderStartTime");
    }

    return () => clearInterval(intervalRef.current);
  }, [state.gamingRigStatus]);

  const startProgress = () => {
    const startTime = localStorage.getItem("loaderStartTime");
    intervalRef.current = setInterval(() => {
      const elapsedTime = Date.now() - Number(startTime);
      const progress = Math.min((elapsedTime / (4 * 60 * 1000)) * 100, 100);
      setState((prevState) => ({ ...prevState, progress }));
      if (progress >= 100) {
        clearInterval(intervalRef.current);
        localStorage.removeItem("loaderStartTime");
      }
    }, 1000);
  };

  const showNotification = (text) => {
    try {
      if (Notification && Notification.permission === "granted") {
        new Notification(text);
        setState((prevState) => ({
          ...prevState,
          hasShownRigStartNotification: true,
        }));
      }
    } catch (err) {
      console.log(err);
    }
  };

  const handleIpCopy = (clipboardText) => {
    navigator.clipboard.writeText(clipboardText);
    setState((prevState) => ({ ...prevState, showCopiedTooltip: true }));
    setTimeout(() => {
      setState((prevState) => ({ ...prevState, showCopiedTooltip: false }));
    }, 1500);
  };

  const handlePinChange = (event) => {
    const { value } = event.target;
    setState((prevState) => ({ ...prevState, pin: value }));
  };

  const isPinInputDisabled = () => {
    return (
      state.gamingRigStatus === gamingRigStatusOptions.client_loading ||
      state.gamingRigStatus === gamingRigStatusOptions.not_running ||
      state.pinSubmitted
    );
  };

  const startGamingRig = async () => {
    try {
      window.dataLayer.push({
        event: "start_gaming_rig",
        selectedGame: document.getElementById("game-selection-dropdown").value,
      });
    } catch (err) {
      console.log(err, "Error in GTM code");
    }
    setState((prevState) => ({
      ...prevState,
      gamingRigStatus: gamingRigStatusOptions.booting,
      loadingRigIsStartingStopping: true,
    }));

    const gameNameToSend = allowNewGameFlag
      ? state.newGameName
      : state.gameName;

    try {
      const startGameResponse = await fetch(
        "https://yx539fwm53.execute-api.ap-south-1.amazonaws.com/default/startGamingRig",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json;charset=UTF-8",
            Authorization: user.signInUserSession.idToken.jwtToken,
          },
          body: JSON.stringify({
            game: gameNameToSend,
            game_size: state.gameSize,
            email: checkoutEmail,
            region: state.region,
            ...(allowNewGameFlag && { store: state.newGameStore }),
          }),
        }
      );

      if (startGameResponse.status === 401) {
        setState((prevState) => ({
          ...prevState,
          startGameRequestError: true,
        }));
      }

      if (startGameResponse.status === 404) {
        setState((prevState) => ({
          ...prevState,
          startGamePleaseWait: true,
        }));
      }

      if (startGameResponse.status === 504) {
        setTimeout(checkInstanceState, 15000);
      }

      await checkInstanceState();
      setState((prevState) => ({
        ...prevState,
        loadingRigIsStartingStopping: false,
      }));

      posthog?.capture("start_game", {
        selectedGame: state.gameName,
        userEmail: user.email,
      });
    } catch (error) {
      setTimeout(checkInstanceState, 15000);
      setState((prevState) => ({
        ...prevState,
        loadingRigIsStartingStopping: false,
      }));
    }
  };

  const shutdownGamingRig = async () => {
    setState((prevState) => ({
      ...prevState,
      loadingRigIsStartingStopping: true,
    }));
    await fetch(
      "https://b9f178yc79.execute-api.ap-south-1.amazonaws.com/default/shutdownGamingRig",
      {
        method: "POST",
        mode: "cors",
        cache: "no-cache",
        credentials: "same-origin",
        headers: {
          "Content-Type": "application/json;charset=UTF-8",
          Authorization: user.signInUserSession.idToken.jwtToken,
        },
        body: JSON.stringify({
          region: state.region,
        }),
      }
    );
    await checkInstanceState();
    setState((prevState) => ({
      ...prevState,
      loadingRigIsStartingStopping: false,
    }));
    posthog?.capture("stop_game", {
      selectedGame: state.gameName,
      userEmail: user.email,
    });
  };

  const setPin = async () => {
    setState((prevState) => ({
      ...prevState,
      pinSubmitted: true,
      showPinLoader: true,
    }));
    try {
      await fetch(`${state.pinUrl}${state.pin}`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json;charset=UTF-8",
          Authorization: user.signInUserSession.idToken.jwtToken,
        },
      });
      setState((prevState) => ({
        ...prevState,
        pinSubmitted: false,
        showPinLoader: false,
        showPinTick: true,
      }));
    } catch (err) {
      console.log(err);
    }
    posthog?.capture("set_pin", {
      selectedGame: state.gameName,
      userEmail: user.email,
    });
  };

  const setPaymentPlanState = (gamingRigStatusJson) => {
    const { max_bitrate, hours_remaining, isHourlyPlan, vm, vmExpiry } =
      gamingRigStatusJson;
    const vmDate = new Date(vmExpiry * 1000);
    setState((prevState) => ({
      ...prevState,
      vmExpiry: vmDate,
      vm: vm && !isDateInPast(vmDate),
      hasHours: hours_remaining > 0.01,
    }));
  };

  const checkInstanceState = async () => {
    try {
      const gamingRigStatusResp = await fetch(gamingRigStatusLink, {
        method: "GET",
        headers: {
          "Content-Type": "application/json;charset=UTF-8",
          Authorization: user.signInUserSession.idToken.jwtToken,
        },
      });
      if (!gamingRigStatusResp.ok) {
        throw new Error(`HTTP error! status: ${gamingRigStatusResp.status}`);
      }
      const gamingRigStatusJson = await gamingRigStatusResp.json();
      const gamingRigStatusStr = gamingRigStatusJson.state;

      setPaymentPlanState(gamingRigStatusJson);

      if (gamingRigStatusStr === "not_running") {
        setState((prevState) => ({
          ...prevState,
          gamingRigStatus: gamingRigStatusOptions.not_running,
          hoursRemaining: gamingRigStatusJson.hours_remaining,
          hasShownRigStartNotification: false,
        }));
      } else if (gamingRigStatusStr === "booting") {
        setState((prevState) => ({
          ...prevState,
          gamingRigStatus: gamingRigStatusOptions.booting,
          hoursRemaining: gamingRigStatusJson.hours_remaining,
          gameName: gamingRigStatusJson.game_name,
          region: gamingRigStatusJson.region,
        }));
        setTimeout(() => {
          checkInstanceState();
        }, 10000);
      } else if (
        gamingRigStatusStr === "running" ||
        gamingRigStatusStr === "starting"
      ) {
        !state.hasShownRigStartNotification && showNotification("Game ready.");
        setState((prevState) => ({
          ...prevState,
          gamingRigStatus: gamingRigStatusOptions.running,
          gamingRigIp: gamingRigStatusJson.public_ip,
          pinUrl: gamingRigStatusJson.pin_url,
          hoursRemaining: gamingRigStatusJson.hours_remaining,
          gameName: gamingRigStatusJson.game_name,
          region: gamingRigStatusJson.region,
        }));
        setTimeout(() => {
          checkInstanceState();
        }, 120000);
      }
    } catch (err) {
      console.log("error in checkInstanceState", err);
    }
  };

  const handleGameChange = async (event) => {
    const { value } = event.target;
    if (value === "VM" && state.closestRegion) {
      setState((prevState) => ({
        ...prevState,
        region: state.closestRegion,
        gameName: value,
        showGameSuggestion: value === "...request game",
      }));
    } else {
      setState((prevState) => ({
        ...prevState,
        region: "ap-south-1",
        gameName: value,
        showGameSuggestion: value === "...request game",
      }));
    }
  };

  const handleRegionChange = (event) => {
    const { value } = event.target;
    setState((prevState) => ({
      ...prevState,
      region: value,
    }));
  };

  const handleNewGameChange = (event) => {
    const { value } = event.target;
    setState((prevState) => ({ ...prevState, newGameName: value }));
  };

  const handleGameSizeChange = (event) => {
    const { value } = event.target;
    setState((prevState) => ({ ...prevState, gameSize: value }));
  };

  const handleNewGameStoreChange = (event) => {
    const { value } = event.target;
    setState((prevState) => ({ ...prevState, newGameStore: value }));
  };

  const { userEmail } = user;
  const allowGameplay =
    state.gamingRigStatus === gamingRigStatusOptions.client_loading ||
    state.hasHours;

  const addHoursButton = (
    <div className="buy-section">
      <Link to="pricing" state={{ email: userEmail }}>
        <CustomButton isSecondary>{t("add_more_hours")}</CustomButton>
      </Link>
    </div>
  );

  const buyButtonOrPlanExpiryDate = addHoursButton;

  const expiredMessage = <p>Continue playing by purchasing more hours</p>;

  const hours = Math.floor(state.hoursRemaining);
  const minutes = Math.floor((state.hoursRemaining * 60) % 60);

  let hoursRemainingStr = `${hours}h ${minutes}m`;

  if (state.hoursRemaining < 0) {
    hoursRemainingStr = "0h 0m";
  }

  return (
    <div>
      <ReferralBanner />
      <div className="relative flex flex-col lg:flex-row">
        <div className="lg:w-1/2">
          <div>
            <section>
              <div className="p-[10px] h-[120px] lg:w-[400px] bg-base-200 text-3xl flex flex-col items-center justify-center text-neutral-content rounded-lg border border-neutral">
                <div className="items-center flex flex-col">
                  <div>
                    {state.vm ? (
                      <ComputerDesktopIcon className="w-5 h-5 text-success inline" />
                    ) : (
                      <ExclamationCircleIcon className="w-5 h-5 text-error inline" />
                    )}
                    <span className="pl-4 text-xl">
                      {state.vm
                        ? t("virtual_gaming_pc_active")
                        : t("virtual_gaming_pc_inactive")}
                    </span>
                  </div>
                  <div className="pl-4 text-xs">
                    {state.vm ? (
                      `until ${state.vmExpiry.toDateString()}`
                    ) : (
                      <a href="/pricing" className="underline">
                        {t("virtual_gaming_pc_buy_now")}
                      </a>
                    )}
                  </div>
                </div>
                <div className="mt-4 text-center">
                  {hoursRemainingStr} {t("remaining")}
                </div>
              </div>
            </section>
          </div>
          {allowGameplay ? (
            <div className="pt-4">
              <section>
                <strong className="mt-8">
                  <span className="mr-2 inline-block">{t("state")}</span>
                  {state.gamingRigStatus ===
                    gamingRigStatusOptions.client_loading && (
                    <div className="h-auto inline-flex items-center flex flex-col">
                      <img
                        className="h-[42px]"
                        src="./loader.svg"
                        alt="loader"
                      />
                      <span>{t(gamingRigStatusOptions.client_loading)}</span>
                    </div>
                  )}

                  {state.gamingRigStatus ===
                    gamingRigStatusOptions.not_running && (
                    <span>{t(gamingRigStatusOptions.not_running)}</span>
                  )}

                  {state.gamingRigStatus === gamingRigStatusOptions.booting && (
                    <div
                      className="h-auto inline-flex items-center flex flex-col"
                      style={{ display: "flex", alignItems: "center" }}
                    >
                      <div>
                        <img
                          className="h-[42px]"
                          src="./loader.svg"
                          alt="loader"
                        />
                        <span>{t(gamingRigStatusOptions.booting)}</span>
                      </div>
                      <div className="block w-[300px] h-[10px] bg-neutral rounded-full mt-[20px]">
                        <div
                          className="h-[10px] bg-success rounded-full"
                          style={{ width: `${state.progress}%` }}
                        ></div>
                      </div>
                    </div>
                  )}

                  {state.gamingRigStatus === gamingRigStatusOptions.running && (
                    <span className="text-success">
                      {t(gamingRigStatusOptions.running)}
                    </span>
                  )}
                </strong>
                <div className="relative mt-6 flex items-center">
                  <span className="mr-2">IP:</span>
                  {state.gamingRigIp &&
                  state.gamingRigStatus === gamingRigStatusOptions.running ? (
                    <span className="inline-flex items-center">
                      <span className="ip-addr">{state.gamingRigIp}</span>
                      <MdCopyAll
                        size="1.5rem"
                        onClick={() => handleIpCopy(state.gamingRigIp)}
                      />
                      <span
                        className={classNames({
                          "copied-tooltip": true,
                          show: state.showCopiedTooltip,
                        })}
                        hidden={!state.showCopiedTooltip}
                      >
                        {t("copied")}
                      </span>
                    </span>
                  ) : (
                    t("not_set")
                  )}
                </div>
                <div className="relative mt-6 flex items-center">
                  <span className="mr-2 inline-flex items-center">PIN:</span>
                  <input
                    className="input input-sm input-bordered"
                    disabled={isPinInputDisabled()}
                    type="text"
                    name="pin"
                    value={state.pin}
                    onChange={handlePinChange}
                  />
                  <button
                    onClick={setPin}
                    disabled={
                      state.gamingRigStatus ===
                        gamingRigStatusOptions.not_running || state.pinSubmitted
                    }
                    type="submit"
                    className="btn btn-sm btn-primary ml-4"
                  >
                    {t("set_pin")}
                  </button>
                  {state.showPinLoader && (
                    <img className="h-[42px]" src="./loader.svg" alt="loader" />
                  )}
                  {state.showPinTick && !state.showPinLoader && (
                    <MdCheck className="text-success" size="1.5rem" />
                  )}
                </div>

                <GameSelection
                  vm={state.vm}
                  isYourOwnGamePlan={state.isYourOwnGamePlan}
                  allowNewGame={state.allowNewGame}
                  isDisabled={
                    state.gamingRigStatus !==
                      gamingRigStatusOptions.not_running ||
                    state.loadingRigIsStartingStopping
                  }
                  gameName={state.gameName}
                  region={state.region}
                  gameSize={state.gameSize}
                  newGameStore={state.newGameStore}
                  handleGameChange={handleGameChange}
                  handleNewGameChange={handleNewGameChange}
                  handleGameSizeChange={handleGameSizeChange}
                  handleRegionChange={handleRegionChange}
                  handleNewGameStoreChange={handleNewGameStoreChange}
                  isHourlyPlan={state.isHourlyPlan}
                  accessAllGames={state.accessAllGames}
                />

                {state.startGamePleaseWait && (
                  <span className="mr-2">{t("please_wait_storing_data")}</span>
                )}
                <input
                  className={clsx("w-36 mx-auto", {
                    hidden: user.username !== "utkarshdalal",
                  })}
                  placeholder="customer email"
                  onChange={(ev) => setCheckoutEmail(ev.target.value)}
                />

                {state.showGameSuggestion && <SuggestGame user={user} />}

                {!state.startGameRequestError ? (
                  <div>
                    {state.gamingRigStatus ===
                      gamingRigStatusOptions.not_running ||
                    state.gamingRigStatus ===
                      gamingRigStatusOptions.client_loading ? (
                      <CustomButton
                        handleClick={startGamingRig}
                        isDisabled={
                          state.showGameSuggestion ||
                          state.loadingRigIsStartingStopping ||
                          state.gamingRigStatus !==
                            gamingRigStatusOptions.not_running
                        }
                        className="start-gamingrig-button"
                      >
                        <MdOutlinePlayCircleFilled size={24} />
                        <span>{t("start_game")}</span>
                      </CustomButton>
                    ) : (
                      <CustomButton
                        handleClick={shutdownGamingRig}
                        isDisabled={state.loadingRigIsStartingStopping}
                        className="stop-gamingrig-button"
                      >
                        <MdStopCircle size={24} />
                        {t("stop_game")}
                      </CustomButton>
                    )}
                  </div>
                ) : (
                  <div className="p-[10px] h-[120px] lg:w-[400px] bg-base-200 text-3xl flex flex-col items-center justify-center text-neutral-content rounded-lg border border-neutral text-error">
                    Your session has expired, please refresh the page
                  </div>
                )}
              </section>
            </div>
          ) : (
            <div>{expiredMessage}</div>
          )}
          {buyButtonOrPlanExpiryDate}
        </div>
        <div className="divider lg:divider-horizontal"></div>
        <div className="lg:w-1/2">{<InstructionsContainer />}</div>
      </div>
    </div>
  );
};

export default Dashboard;
