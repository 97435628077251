export const gameList = [
  {
    name: "Apex Legends",
    store: "Steam",
    image: "apex_legends",
  },
  {
    name: "Battlefield 2042",
    store: "Steam",
  },
  {
    name: "Call of Duty Warzone 2",
    store: "Steam",
  },
  {
    name: "Cricket 24",
    store: "Steam",
  },
  {
    name: "Elden Ring",
    store: "Steam",
  },
  {
    name: "Euro Truck Simulator 2",
    store: "Steam",
  },
  {
    name: "Fall Guys",
    store: "Epic",
  },
  {
    name: "FC 24",
    store: "Steam",
  },
  {
    name: "FC 25",
    store: "Steam",
  },
  {
    name: "Forza Horizon 4",
    store: "Steam",
  },
  {
    name: "God of War",
    store: "Steam",
  },
  {
    name: "Grand Theft Auto V Steam",
    store: "Steam",
    hourlyPlanOnly: true,
  },
  {
    name: "Grand Theft Auto V",
    store: "Epic",
    hourlyPlanOnly: true,
  },
  {
    name: "HITMAN 3",
    store: "Steam",
  },
  {
    name: "Hogwarts Legacy",
    store: "Steam",
  },
  {
    name: "Need for Speed Unbound",
    store: "Steam",
  },
  {
    name: "Red Dead Redemption 2",
    store: "Steam",
  },
  {
    name: "Spider-Man Remastered",
    store: "Steam",
  },
  {
    name: "Spider-Man Miles Morales",
    store: "Steam",
  },
  {
    name: "The Witcher 3 Wild Hunt",
    store: "Steam",
  },
  {
    name: "UNCHARTED Legacy of Thieves Collection",
    store: "Steam",
  },
  {
    name: "WWE 2K23",
    store: "Steam",
  },
];
